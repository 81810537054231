.grecaptcha-badge {
  visibility: hidden;
}

@font-face {
  font-family: 'roboto-bold';
  src: url('../../assets/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-medium';
  src: url('../../assets/fonts/Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-regular';
  src: url('../../assets/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'almarai-bold';
  src: url('../../assets/fonts/Almarai-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'almarai-medium';
  src: url('../../assets/fonts/Almarai-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'almarai-regular';
  src: url('../../assets/fonts/Almarai-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'tajawal-bold';
  src: url('../../assets/fonts/Tajawal-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'tajawal-medium';
  src: url('../../assets/fonts/Tajawal-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'tajawal-regular';
  src: url('../../assets/fonts/Tajawal-Light.ttf') format('truetype');
}
